import axios from "axios";
import { router } from "../main.js";

//window._ = require("lodash");

import toast from "../services/toast";
import store from "../store";

export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

/* ****** Request Interceptor ****** */
axiosInstance.interceptors.request.use(function (config) {
  let user = null;
  const activeProject = JSON.parse(localStorage.getItem("activeProject"));
  if (store.state.auth.token) user = JSON.parse(localStorage.getItem("user"));

  const hjSessionId = localStorage.getItem("hjSessionId");
  config.headers = { ...config.headers, "x-hotjar-session-id": hjSessionId };

  config.headers.Authorization = "Bearer " + store.state.auth.token;
  if (user && user.type == 2 && activeProject) {
    config.params = { ...config.params, project_id: activeProject.id };
  }

  return config;
});

/* ****** Response Interceptor ****** */
axiosInstance.interceptors.response.use(undefined, function (err) {
  return new Promise(function () {
    const authRoutes = ["home/login", "/home/signUp"];

    if (
      err.response.status === 401 &&
      err.response.config &&
      !err.response.config.__isRetryRequest
    ) {
      if (!authRoutes.includes(window.location.pathname)) {
        store.dispatch("auth/removeAuthData");
      }
    } else if (
      err.response.status == 422 &&
      [
        "The email must be a business email address.",
        "البريد الالكتروني يجب ان يكون بريد عمل",
      ].includes(err.response.data.errors[0].error)
    ) {
      throw err;
    } else if (
      err.response.status == 400 &&
      [
        "The email must be a business email address.",
        "البريد الالكتروني يجب ان يكون بريد عمل",
      ].includes(err.response.data.message)
    ) {
      throw err;
    } else if (
      err.response.status == 400 &&
      err.response.config.url === "api/auth/social/register" &&
      [
        "This email already registered, Try to login",
        "البريد الالكتروني مسجل بالفعل٫ برجاء تسجيل الدخول",
        "This email is used as a talent’s email, please choose another email",
        "برجاء اختيار بريد الكتروني اخر",
      ].includes(err.response.data.message)
    ) {
      throw err;
    }
    if (
      err.response.status === 400 &&
      err.response.config.url === "/api/auth/register/one-step-client-wizard" &&
      [
        "This email already registered, Try to login",
        "البريد الالكتروني مسجل بالفعل٫ برجاء تسجيل الدخول",
        "This email is used as a talent’s email, please choose another email",
        "برجاء اختيار بريد الكتروني اخر",
      ].includes(err.response.data.message)
    ) {
      throw err;
    } else if (
      err.response.status === 422 &&
      err.response.config.url === "/api/auth/validate-email" &&
      [
        "The email has already been taken.",
        "قيمة الحقل البريد الإلكتروني مُستخدمة من قبل",
      ].includes(err.response.data.errors?.[0]?.error)
    ) {
      throw err;
    } else if (err.response.status == 422) {
      toast.error(err.response.data.errors?.[0]?.error);
      throw err;
    } else if (err.response.status == 403 || err.response.status == 404) {
      if (
        err.response.data.message ===
          "You are not authorized for this request." ||
        err.response.data.message ===
          "Nomination has been Expired, please contact an account manager for any inquiries"
      ) {
        throw err;
      }
      toast.error(err.response.data.errors?.[0]?.error);
    }
    if (err.response.data.message === "Unauthenticated") {
      store.dispatch("auth/removeAuthData");
      router.push({ path: "/login" });
      window.location.reload();
      // window.location.assign("/login");
    }
    toast.error(err.response.data.message);
    throw err;
  });
});
